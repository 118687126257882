<template>
  <div class="personal-center">
    <div class="cover">
      <div class="ym-title-border">个人中心</div>
      <el-form ref="userForm"
               size="small"
               :model="userForm"
               class="user-form"
               :rules="rules"
               label-width="100px">
        <el-row :gutter="16">
          <el-col>
            <el-form-item label="姓名" prop="userName">
              <el-input v-model="userForm.userName" size="small" placeholder="请输入" maxlength="40" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col>
            <el-form-item prop="userEmail" label="邮箱">
              <el-input v-model="userForm.userEmail" size="small" placeholder="请输入" maxlength="100" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col>
            <el-form-item prop="mobilePhone" label="联系电话">
              <el-input v-model="userForm.mobilePhone" size="small" placeholder="请输入" maxlength="20" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col>
            <el-form-item prop="deptName" label="所属机构">
              <p>{{userForm.deptName}}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col>
            <el-form-item prop="userGroup" label="所属角色">
              <template v-for="role in userForm.groups">
                <el-tag :key="role.groupId" size="small" class="role-tag">{{role.groupDescription}}</el-tag>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="bottom">
        <el-button @click="handleCancel" size="small">取消</el-button>
        <el-button type="primary"
                   @click="handleSubmit"
                   size="small">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/localStorage.js'
export default {
  data() {
    // let checkPhone = (rule, value, callback) => {
    //   const regExp = /^1\d{10}$/
    //   if (!value) {
    //     callback()
    //   } else if (!regExp.test(value)) {
    //     callback(new Error('请填写正确的手机号码'))
    //   } else {
    //     callback()
    //   }
    // }
    let checkEmail = (rule, value, callback) => {
      const regExp = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      if (!value) {
        callback(new Error('请输入工作邮箱'))
      } else if (!regExp.test(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    return {
      loginAccount: '',
      userForm: {
        userName: '',
        userEmail: '',
        mobilePhone: '',
        deptName: '',
        groups: []
      },
      rules: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'change' }
        ],
        userEmail: [
          { required: true, validator: checkEmail, trigger: 'change' }
        ]
        // mobilePhone: [
        //   { validator: checkPhone, trigger: 'change' }
        // ]
      }
    }
  },
  mounted() {
    this.loginAccount = JSON.parse(getUserInfo()).userLoginAccount
    console.log(getUserInfo())
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      try {
        const { data } = await this.$api.user.getPersonalInfo()
        if (data.code === 0) {
          let userInfo = data.data
          userInfo.userName && this.$set(this.userForm, 'userName', userInfo.userName)
          userInfo.userEmail && this.$set(this.userForm, 'userEmail', userInfo.userEmail)
          this.$set(this.userForm, 'mobilePhone', userInfo.mobilePhone)
          this.$set(this.userForm, 'deptName', userInfo.deptName)
          this.$set(this.userForm, 'groups', userInfo.groups)
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 更新用户信息
    async updateInfo() {
      this.submitLoading = true
      try {
        const { data } = await this.$api.user.updatePersonalInfo({
          userName: this.userForm.userName,
          userEmail: this.userForm.userEmail,
          mobilePhone: this.userForm.mobilePhone
        })
        if (data.code === 0) {
          this.submitLoading = false
          this.$message({
            type: 'success',
            message: '保存成功'
          })
        } else {
          this.submitLoading = false
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.submitLoading = false
        throw new Error(err)
      }
    },
    handleSubmit() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.updateInfo()
        } else {
          return false
        }
      })
    },
    handleCancel() {
      this.$router.back()
      // this.$router.push('/home')
    }
  }
}
</script>

<style lang='scss' scoped>
.personal-center {
  min-width: 440px;
  background-color: $--header-bg-color;
  border: $--header-border;
  border-radius: $--border-radius-base;
  padding: 20px 24px 43px;
  margin-bottom: 20px;
  color: $--color-text-primary;
  .user-form {
    width: 500px;
    min-width: 500px;
  }
  .ym-title {
    margin-top: 20px;
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    width: 500px;
    min-width: 500px;
    .el-button {
      width: 80px;
    }
  }
  .role-tag + .role-tag {
    margin-left: 8px;
  }
}
</style>
